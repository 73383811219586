import request from '@/api/axios-request'

const apiGames = {
  
  getLatest() {
    return request({
      url: '/pl/games/latestGames',
      method: 'get',
      params: {
        siteCode: 'S7'
      }
    })
  },

  listGames(page, limit, sort = null, search = null, type = null) {
    return request({
      url: '/pl/games/listGames',
      method: 'get',
      params: {
        page: page,
        limit: limit,
        sort: sort,
        search: search,
        type: type,
        siteCode: 'S7'
      }
    })
  },

  launchFunkyGame(gameCode, fkToken) {
    return request({
      url: '/pl/games/launchFunkyGame',
      method: 'post',
      data: {
        gameCode,
        fkToken
      }
    })
  },

  launchBgamingDemoGame(gameIdentifier, clientType, returnUrl) {
    return request({
      url: '/pl/games/launchBgamingDemoGame',
      method: 'post',
      data: {
        gameIdentifier,
        clientType,
        returnUrl
      }
    })
  },

  launchBgamingLiveGame(gameIdentifier, clientType, returnUrl) {
    return request({
      url: '/pl/games/launchBgamingLiveGame',
      method: 'post',
      data: {
        gameIdentifier,
        clientType,
        returnUrl
      }
    })
  },

  launchJokerDemoGame(providerID, returnUrl, clientType) {
    return request({
      url: '/pl/games/launchJokerDemoGame',
      method: 'post',
      data: {
        providerID,
        returnUrl,
        clientType,
        siteCode: 'S7'
      }
    })
  },

  launchJokerLiveGame(providerID, returnUrl, clientType) {
    return request({
      url: '/pl/games/launchJokerLiveGame',
      method: 'post',
      data: {
        providerID,
        returnUrl,
        clientType
      }
    })
  },

  launchKagaDemoGame(providerID, returnUrl, clientType) {
    return request({
      url: '/pl/games/launchKagaDemoGame',
      method: 'post',
      data: {
        providerID,
        returnUrl,
        clientType,
        siteCode: 'S7'
      }
    })
  },

  launchKagaLiveGame(providerID, returnUrl, clientType) {
    return request({
      url: '/pl/games/launchKagaLiveGame',
      method: 'post',
      data: {
        providerID,
        returnUrl,
        clientType
      }
    })
  },

  launchOnlyplayDemoGame(bundle, returnUrl) {
    return request({
      url: '/pl/games/launchOnlyplayDemoGame',
      method: 'post',
      data: {
        bundle,
        returnUrl
      }
    })
  },

  launchOnlyplayLiveGame(bundle, returnUrl) {
    return request({
      url: '/pl/games/launchOnlyplayLiveGame',
      method: 'post',
      data: {
        bundle,
        returnUrl
      }
    })
  },

  launchPlaystarDemoGame(providerID, returnUrl) {
    return request({
      url: '/pl/games/launchPlaystarDemoGame',
      method: 'post',
      data: {
        providerID,
        returnUrl,
        siteCode: 'S7'
      }
    })
  },

  launchPlaystarLiveGame(providerID, returnUrl) {
    return request({
      url: '/pl/games/launchPlaystarLiveGame',
      method: 'post',
      data: {
        providerID,
        returnUrl
      }
    })
  },

  launchVpowerDemoGame(GameID, returnUrl) {
    return request({
      url: '/pl/games/launchVpowerDemoGame',
      method: 'post',
      data: {
        GameID,
        returnUrl,
        siteCode: 'S7'
      }
    })
  },

  launchVpowerLiveGame(GameID, returnUrl) {
    return request({
      url: '/pl/games/launchVpowerLiveGame',
      method: 'post',
      data: {
        GameID,
        returnUrl
      }
    })
  },

  playstarCloseFTBGame() {
    return request({
      url: '/pl/games/playstarCloseFTBGame',
      method: 'post'
    })
  },

  vpowerCloseGame() {
    return request({
      url: '/pl/games/vpowerCloseGame',
      method: 'post'
    })
  }
  
}

export default apiGames